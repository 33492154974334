import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './guide.scss';
import { WWCharacter } from '../../../modules/ww/common/components/ww-character';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const WWGuidesAscMaps: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ww-guide'} game="ww">
      <ul className="breadcrumb">
        <li>
          <Link to="/wuthering-waves/">Wuthering Waves</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/wuthering-waves/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Ascension material maps</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/ww/categories/category_map.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Wuthering Waves Ascension material maps</h1>
          <h2>
            Find the best spots to farm Belly Poppy, Iris, Coriolus, Wintry
            Bell, Lanternberry and Pecok Flower ascension materials in Wuthering
            Waves!
          </h2>
          <p>
            Last updated: <strong>20/05/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Belle Poppy" />
        <StaticImage src="../../../images/ww/generic/asc_1.webp" alt="Guide" />
        <p>
          <strong>Belle Poppy</strong> is one of the Ascension materials in
          Wuthering Waves that you need to gather around the map. Here are the
          characters that need it to ascend:
        </p>
        <div className="employee-container for-nikke">
          <WWCharacter mode="icon" slug="chixia" enablePopover showLabel />
          <WWCharacter mode="icon" slug="verina" enablePopover showLabel />
          <WWCharacter mode="icon" slug="danjin" enablePopover showLabel />
        </div>
        <h5>Map</h5>
        <StaticImage
          src="../../../images/ww/generic/asc_1_map.webp"
          alt="Guide"
        />
        <p>
          Here's the map of the area where you can find{' '}
          <strong>Belle Poppy</strong>, but we suggest using the interactive map
          as you can zoom in/out to make it easier to find it. Just use the left
          panel to toggle the material you want.
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Interactive Map"
            link="https://www.ghzs666.com/wutheringwaves-map#/"
            image={
              <StaticImage
                src="../../../images/ww/categories/category_map.jpg"
                alt="Map"
              />
            }
            outsideLink
          />
        </Row>
        <SectionHeader title="Wintry Bell" />
        <StaticImage src="../../../images/ww/generic/asc_2.webp" alt="Guide" />
        <p>
          <strong>Wintry Bell</strong> is one of the Ascension materials in
          Wuthering Waves that you need to gather around the map. Here are the
          characters that need it to ascend:
        </p>
        <div className="employee-container for-nikke">
          <WWCharacter mode="icon" slug="sanhua" enablePopover showLabel />
          <WWCharacter mode="icon" slug="yangyang" enablePopover showLabel />
          <WWCharacter mode="icon" slug="aalto" enablePopover showLabel />
        </div>
        <h5>Map</h5>
        <StaticImage
          src="../../../images/ww/generic/asc_2_map.webp"
          alt="Guide"
        />
        <p>
          Here's the map of the area where you can find{' '}
          <strong>Wintry Bell</strong>, but we suggest using the interactive map
          as you can zoom in/out to make it easier to find it. Just use the left
          panel to toggle the material you want.
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Interactive Map"
            link="https://www.ghzs666.com/wutheringwaves-map#/"
            image={
              <StaticImage
                src="../../../images/ww/categories/category_map.jpg"
                alt="Map"
              />
            }
            outsideLink
          />
        </Row>
        <SectionHeader title="Lanternberry" />
        <StaticImage src="../../../images/ww/generic/asc_3.webp" alt="Guide" />
        <p>
          <strong>Lanternberry</strong> is one of the Ascension materials in
          Wuthering Waves that you need to gather around the map. Here are the
          characters that need it to ascend:
        </p>
        <div className="employee-container for-nikke">
          <WWCharacter mode="icon" slug="baizhi" enablePopover showLabel />
          <WWCharacter mode="icon" slug="jianxin" enablePopover showLabel />
        </div>
        <h5>Map</h5>
        <StaticImage
          src="../../../images/ww/generic/asc_3_map.webp"
          alt="Guide"
        />
        <p>
          Here's the map of the area where you can find{' '}
          <strong>Lanternberry</strong>, but we suggest using the interactive
          map as you can zoom in/out to make it easier to find it. Just use the
          left panel to toggle the material you want.
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Interactive Map"
            link="https://www.ghzs666.com/wutheringwaves-map#/"
            image={
              <StaticImage
                src="../../../images/ww/categories/category_map.jpg"
                alt="Map"
              />
            }
            outsideLink
          />
        </Row>
        <SectionHeader title="Coriolus" />
        <StaticImage src="../../../images/ww/generic/asc_4.webp" alt="Guide" />
        <p>
          <strong>Coriolus</strong> is one of the Ascension materials in
          Wuthering Waves that you need to gather around the map. Here are the
          characters that need it to ascend:
        </p>
        <div className="employee-container for-nikke">
          <WWCharacter mode="icon" slug="lingyang" enablePopover showLabel />
          <WWCharacter mode="icon" slug="mortefi" enablePopover showLabel />
          <WWCharacter mode="icon" slug="yinlin" enablePopover showLabel />
        </div>
        <h5>Map</h5>
        <StaticImage
          src="../../../images/ww/generic/asc_4_map.webp"
          alt="Guide"
        />
        <p>
          Here's the map of the area where you can find{' '}
          <strong>Coriolus</strong>, but we suggest using the interactive map as
          you can zoom in/out to make it easier to find it. Just use the left
          panel to toggle the material you want.
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Interactive Map"
            link="https://www.ghzs666.com/wutheringwaves-map#/"
            image={
              <StaticImage
                src="../../../images/ww/categories/category_map.jpg"
                alt="Map"
              />
            }
            outsideLink
          />
        </Row>
        <SectionHeader title="Pecok Flower" />
        <StaticImage src="../../../images/ww/generic/asc_5.webp" alt="Guide" />
        <p>
          <strong>Pecok Flower</strong> is one of the Ascension materials in
          Wuthering Waves that you need to gather around the map. Here are the
          characters that need it to ascend:
        </p>
        <div className="employee-container for-nikke">
          <WWCharacter mode="icon" slug="encore" enablePopover showLabel />
          <WWCharacter mode="icon" slug="jiyan" enablePopover showLabel />
          <WWCharacter
            mode="icon"
            slug="rover-spectro"
            enablePopover
            showLabel
          />
        </div>
        <h5>Map</h5>
        <StaticImage
          src="../../../images/ww/generic/asc_5_map.webp"
          alt="Guide"
        />
        <p>
          Here's the map of the area where you can find{' '}
          <strong>Pecok Flower</strong>, but we suggest using the interactive
          map as you can zoom in/out to make it easier to find it. Just use the
          left panel to toggle the material you want.
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Interactive Map"
            link="https://www.ghzs666.com/wutheringwaves-map#/"
            image={
              <StaticImage
                src="../../../images/ww/categories/category_map.jpg"
                alt="Map"
              />
            }
            outsideLink
          />
        </Row>
        <SectionHeader title="Iris" />
        <StaticImage src="../../../images/ww/generic/asc_6.webp" alt="Guide" />
        <p>
          <strong>Iris</strong> is one of the Ascension materials in Wuthering
          Waves that you need to gather around the map. Here are the characters
          that need it to ascend:
        </p>
        <div className="employee-container for-nikke">
          <WWCharacter mode="icon" slug="calcharo" enablePopover showLabel />
          <WWCharacter mode="icon" slug="taoqi" enablePopover showLabel />
        </div>
        <h5>Map</h5>
        <StaticImage
          src="../../../images/ww/generic/asc_6_map.webp"
          alt="Guide"
        />
        <p>
          Here's the map of the area where you can find <strong>Iris</strong>,
          but we suggest using the interactive map as you can zoom in/out to
          make it easier to find it. Just use the left panel to toggle the
          material you want.
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Interactive Map"
            link="https://www.ghzs666.com/wutheringwaves-map#/"
            image={
              <StaticImage
                src="../../../images/ww/categories/category_map.jpg"
                alt="Map"
              />
            }
            outsideLink
          />
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default WWGuidesAscMaps;

export const Head: React.FC = () => (
  <Seo
    title="Ascension material maps | Wuthering Waves | Prydwen Institute"
    description="Find the best spots to farm Belly Poppy, Iris, Coriolus, Wintry Bell, Lanternberry and Pecok Flower ascension materials in Wuthering Waves!"
    game="ww"
  />
);
